<template>
  <Card>
    <AddEditModal :isOpen.sync="showModal" @save="$refs.Table.getData()" />
    <div id="deliveries-inbox-table">
      <div class="flex f-jsb">
        <Input-search
          class="input-search"
          icon="magnify"
          :placeholder="$t('schedule.search.deliveriesInbox')"
          v-model="params.all"
        />
        <div class="buttons">
          <Button
            type="is-primary"
            outlined
            :label="$t('deliveries.add')"
            icon-left="plus"
            @click="showModal = true"
            :permission="Permission.create"
          />
          <Button
            :disabled="checkedRows.length === 0"
            type="is-danger"
            outlined
            :loading="deleteLoading"
            :label="$t('deliveries.deleteSelected')"
            icon-left="delete"
            @click="handleDelete"
            :permission="Permission.delete"
          />
        </div>
      </div>
      <div class="symbology flex f-ai">
        <DeliveryStatusUI type="rush" component="simple-text" />
        <DeliveryStatusUI type="priority" component="simple-text" />
        <DeliveryStatusUI type="refrigerated" component="simple-text" />
        <DeliveryStatusUI type="leave-at-door" component="simple-text" />
        <DeliveryStatusUI type="collect" component="simple-text" />
      </div>
      <MainTable
        ref="Table"
        :loading.sync="loading"
        :params="params"
        @selectedRows="selectedRows"
      />
    </div>
  </Card>
</template>

<script>
import { Button, Card, InputSearch, DeliveryStatusUI } from '@/components';
import AddEditModal from './AddEditModal.vue';
import MainTable from './MainTable.vue';
import { Permissions } from '@/utils/Secure';

export default {
  components: {
    AddEditModal,
    Button,
    Card,
    DeliveryStatusUI,
    InputSearch,
    MainTable
  },
  computed: {
    Permission() {
      return Permissions.DeliveryInbox;
    }
  },
  data() {
    return {
      loading: false,
      deleteLoading: false,
      params: { all: null },
      showModal: false,
      checkedRows: []
    };
  },
  methods: {
    async handleDelete() {
      if (this.checkedRows.length > 0) {
        this.deleteLoading = true;
        try {
          const rowsToDelete = { deliveries_inbox: this.checkedRows.map((e) => e.id) };
          await this.Api.delete(`/deliveries_inbox`, rowsToDelete);
          this.$toast('success', this.$t('schedule.messages.multipleDeleted'), 3000);
          this.$refs.Table.getData();
          this.checkedRows = [];
        } catch (error) {
          console.log(error);
        }
        this.deleteLoading = false;
      }
    },
    selectedRows(e) {
      this.checkedRows = e;
    }
  }
};
</script>

<style lang="sass" scoped>
.card
  height: calc(100% - 100px)
  #deliveries-inbox-table
    width: 100%
    margin-top: 5px
    margin-right: 15px
    .input-search
      width: 40%
  .symbology
    margin-top: 20px
    p
      color: $blue-700
      font-size: 14px
      margin-left: 0.5rem
  ::v-deep
    .rush-icon
      color: $yellow-660
      margin-right: -15px
      font-size: 16px
    .priority-icon
      color: $green-700
      margin-right: -15px
      font-size: 16px
    .refrigerated-text
      margin-left: 0.5rem
    .refrigerated-icon
      font-size: 16px
    .text-icon
      margin-left: 1rem !important
</style>

<style lang="sass">
.dark
  .card
    .symbology
      p
        color: $main-background
</style>
